
.select-field {
  font-size: 12px;

  .MuiSelect-select {
    padding: 8px 9.5px;
  }

  .MuiSelect-icon {
    right: 4px;
  }
}